import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import ProductList from "../components/Content/ProductList";
import { useProductRequests } from "../helpers/ProductRequests";
import { Helmet } from "react-helmet";
import { HomeList } from "../styles/Home";
import BigCategories from "../components/Navigation/BigCategories";

export default function Category(props) {
  const [search, setSearch] = useState("");

  const { slug } = useParams();
  const [products, setProducts] = useState([]);
  const [sort, setSort] = useState({
    sort: "PRICE",
    sortedBy: "ASC",
    name: "Artan Fiyat",
  });
  const [selectedBrand, setSelectedBrand] = useState("");
  const [open, setOpen] = useState(false);

  const { getProducts } = useProductRequests();

  const simpleCount = 20;
  function karsilastir(a, b) {
    // "TÜKENDİ" olanları en sonda olacak şekilde sırala
    if (a.stockText.includes("TÜKENDİ") && !b.stockText.includes("TÜKENDİ")) {
      return 1;
    } else if (
      !a.stockText.includes("TÜKENDİ") &&
      b.stockText.includes("TÜKENDİ")
    ) {
      return -1;
    } else {
      // Diğer durumlarda değişiklik yapma
      return 0;
    }
  }
  const getNewProducts = async (newpage, setLoading, search, setPage) => {
    setLoading(true);
    getProducts({
      brandSlug: selectedBrand,
      sort: sort.sort,
      sortedBy: sort.sortedBy,
      count: 24,
      groupSlug: slug,
      page: newpage,
      all: search,
    }).then(async (response) => {
      setLoading(false);
      if (response.data.data.items && response.data.data.items.length < 1) {
      } else {
        setPage(newpage);
        setProducts([...products, ...response.data.data.items]);
      }
    });
  };
  const getSearchedProducts = (setLoading, setPage, search) => {
    setLoading(true);
    getProducts({
      brandSlug: selectedBrand,
      count: 24,
      groupSlug: slug,
      page: 1,
      all: search,
      sort: sort.sort,
      sortedBy: sort.sortedBy,
    }).then((response) => {
      setLoading(false);
      if (response.data.data.items && response.data.data.items.length < 1) {
      } else {
        setPage(2);
        setProducts(response.data.data.items);
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>Ürün Listesi</title>
      </Helmet>
      <ProductList
        sort={sort}
        setSort={setSort}
        open={open}
        karsilastir={karsilastir}
        setOpen={setOpen}
        setSelectedBrand={setSelectedBrand}
        selectedBrand={selectedBrand}
        slug={slug}
        getNewProducts={getNewProducts}
        getSearchedProducts={getSearchedProducts}
        products={products}
        search={search}
        setSearch={setSearch}
        setProducts={setProducts}
        getProducts={getProducts}
      />
    </>
  );
}

import React,{useEffect,useRef} from 'react'
import Carousel from 'react-elastic-carousel'
import { useDispatch } from 'react-redux';
import SlideProduct from '../../components/Product/SlideProduct'
import useWindowDimensions from '../useWindowDimensions';
import ProductItem from '../../components/Product/ProductItem'
import { ProductList } from '../../styles/Product';
export default function Products({items,mainProducts=false}) {
  const {width}=useWindowDimensions()
  const carouselRef = useRef(null);
  const dispatch = useDispatch()
  let resetTimeout;
  return (
    <>
      {mainProducts ? (
        <ProductList className="product-grid main" >
          {items.map((item, index) => {
            return <ProductItem className="product-item" key={item.id} product={item} />;
          })}
        </ProductList>
      ) : (
        <Carousel
          ref={carouselRef}
          onNextEnd={({ index }) => {
            clearTimeout(resetTimeout);
            if (index === items.length - 5) {
              resetTimeout = setTimeout(() => {
                carouselRef.current.goTo(0);
              }, 1400); // same time
            }
          }}
          onPrevStart={({ index }) => {
            clearTimeout(resetTimeout);
            if (index === 0) {
              resetTimeout = setTimeout(() => {
                carouselRef.current.goTo(items.length - 5);
              }, 1400); // same time
            }
          }}
          disableArrowsOnEnd={false}
          pagination={false}
          onChange={() => {
            //console.log(carouselRef.current.state.activeIndex)
          }}
          itemsToShow={
            width < 1140
              ? width > 980
                ? 2
                : 2
              : width > 1800
              ? 5
              : width < 1400
              ? width < 1000
                ? 3
                : 4
              : 4
          }
        >
          {items.map((item, index) => {
            return <SlideProduct key={item.id} item={item} />;
          })}
        </Carousel>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Login } from "../../styles/Auth";
import Box from "@mui/material/Box";

import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { loginChange, setCustomer } from "../stores/Auth";

import { useAuthRequests } from "../../helpers/AuthRequests";
import { Link } from "react-router-dom";

export default function RegisterForm() {
  const { registerRequest } = useAuthRequests();

  const dispatch = useDispatch();

  const { login } = useSelector((state) => state.auth);

  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const [guarantorName, setGuarantorName] = useState("");
  const [guarantorPhoneNumber, setGuarantorPhoneNumber] = useState("");
  const [surname, setSurname] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [taxOffice, setTaxOffice] = useState("");
  const [personIdNumber, setPersonIdNumber] = useState("");
  const [taxNumber, setTaxNumber] = useState("");

  const [password, setPassword] = useState("");

  const [checked, setChecked] = useState(false);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const history = useHistory();

  const RedditTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
  ))(({ theme }) => ({}));

  const sendRegister = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    } else if (
      email.length < 2 ||
      name.length < 2 ||
      surname.length < 2 ||
      company.length < 5 ||
      phoneNumber.length < 2 ||
      address.length < 2 ||
      country.length < 2 ||
      city.length < 2 ||
      district.length < 2 ||
      password.length < 2 ||
      (personIdNumber.length < 2 &&
        (taxNumber.length < 2 || taxOffice.length < 2))
    ) {
      setError("Zorunlu alanları geçmeyiniz.");
    } else {
      setLoading(true);

      registerRequest({
        companyTitle: company,
        name: name,
        surname: surname,
        address: address,
        phoneNumber: phoneNumber,
        email: email,
        country: country,
        city: city,
        district: district,
        taxOffice: taxOffice,
        taxNumber: taxNumber,
        personIdNumber: personIdNumber,
        guarantorName: guarantorName,
        guarantorPhoneNumber: guarantorPhoneNumber,
        password: password,
      })
        .then(async (response) => {
          setEmail("");
          setPhoneNumber(0);
          setPhoneNumber2(0);
          setAddress("");
          setName("");
          setSurname("");
          setCompany("");
          setChecked(false);
          setError("");
          setSuccess(
            response.data.message + " Giriş syafasına  yönlendiriliyorsunuz."
          );

          setTimeout(() => {
            history.push("/giris-yap");
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          setError(error.response.data.message);
          setLoading(false);
        });
    }
  };

  return (
    <Login>
      <form onSubmit={sendRegister}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="Ticari Ünvan"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            variant="filled"
            type="text"
            style={{ marginTop: -31 }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="Ad"
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="filled"
            type="text"
            style={{ marginTop: 11 }}
          />
          <TextField
            label="Soyad"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            variant="filled"
            type="text"
            style={{ marginTop: 11 }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="Adres"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            variant="filled"
            type="text"
            style={{ marginTop: 11 }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="Ülke "
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            variant="filled"
            type="text"
            style={{ marginTop: 11 }}
          />
          <TextField
            label="İl "
            value={city}
            onChange={(e) => setCity(e.target.value)}
            variant="filled"
            type="text"
            style={{ marginTop: 11 }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="İlçe "
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
            variant="filled"
            type="text"
            style={{ marginTop: 11 }}
          />
          <TextField
            label="TC Kimlik No "
            value={personIdNumber}
            onChange={(e) => setPersonIdNumber(e.target.value)}
            variant="filled"
            type="text"
            style={{ marginTop: 11 }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="Vergi Dairesi "
            value={taxOffice}
            onChange={(e) => setTaxOffice(e.target.value)}
            variant="filled"
            type="text"
            style={{ marginTop: 11 }}
          />
          <TextField
            label="Vergi No "
            value={taxNumber}
            onChange={(e) => setTaxNumber(e.target.value)}
            variant="filled"
            type="text"
            style={{ marginTop: 11 }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="Cep Telefonu "
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            variant="filled"
            type="number"
            style={{ marginTop: 11 }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="E Posta"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="filled"
            type="email"
            style={{ marginTop: 11 }}
          />
          <TextField
            label="Parola"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="filled"
            type="password"
            style={{ marginTop: 11 }}
          />
        </Box>

        <label>
          {/* <Checkbox checked={checked} onChange={()=>setChecked(!checked)} {...label}  /> Beni hatırla */}
        </label>
        {error != "" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 1 },
            }}
          >
            {" "}
            <div className="error-area">{error}</div>{" "}
          </Box>
        )}
        {success != "" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 1 },
            }}
          >
            {" "}
            <div className="success-area">{success}</div>{" "}
          </Box>
        )}
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          className="authBtn"
        >
          Kayıt Başvurusu Gönder
        </LoadingButton>

        <div className="form-navigator">
          <Link style={{ color: "#fff" }} to="/giris-yap">
            Giriş Yap
          </Link>
          {/* <Link to="/sifremi-unuttum" >Şifremi Unuttum</Link>   */}
        </div>
      </form>
    </Login>
  );
}

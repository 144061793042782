import React,{useState,useLayoutEffect} from 'react'
import { useSelector } from 'react-redux'
import {SlideProductContainer} from '../styles/Home'
import SlideProducts from '../components/Slider/Products'
import Header from '../components/Navigation/Header'
import BottomNav from '../components/Navigation/Bottom'
import Footer from '../components/Navigation/Footer'
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader"

import {useProductRequests} from '../helpers/ProductRequests'
import {useOrderRequests} from '../helpers/OrderRequests'
import { DetailContainer } from '../styles/Product'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faSearch, faSearchPlus } from '@fortawesome/free-solid-svg-icons'
import { Sentry } from 'react-activity'
import { useHistory } from 'react-router-dom';
import { Currency } from "react-intl-number-format/dist/index.js";

export default function Collections(props) {
  
  const { getMainProducts } = useProductRequests();
  const {getCollections} = useOrderRequests()
  
  const {basket}=useSelector(state=>state.cart)

  const [discover, setDiscover] = useState([]);
  const [loading, setLoading] = useState(true);
  const [collections,setCollections]=useState([])

  useLayoutEffect(() => {
    
    if(discover.length===0){

      getMainProducts({ type: 1 })
        .then((response) => {
          setDiscover(response.data.data.items);
        })
        .catch((error) => {});

    }
  }, [])
  const [printOn, setPrintOn] = React.useState(false);
  const printPdf = () => {
    setTimeout(() => {
      setPrintOn(false);
    }, 5500);
  };

  const history=useHistory()
  useLayoutEffect(() => {
    
      if(collections.length===0){

      getCollections()
        .then((response) => {
          setLoading(false);
          setCollections(response.data.data.collections);
        })
        .catch((error) => {
          console.log("error", error.response);
          setLoading(false);
        });

      }
    }, [])
    const dateSort = (rowA, rowB) => {
      const a = new Date(rowA.date);
      const b = new Date(rowB.date);

      if (a > b) {
        return 1;
      }

      if (b > a) {
        return -1;
      }

      return 0;
    };
    const columns = [
      {
        name: "İşlem Türü",
        // selector: row => <> <Link to={"/siparisler/"+row.orderId} > <FontAwesomeIcon icon={faEye} /> </Link> </>,
        selector: (row) => (
          <div
            onClick={() => {
              if ((row.collectionType || "").indexOf("FATURA") != -1) {
                history.push("/faturalar/" + row.documentNumber);
                return;
              }
              if (printOn == false) {
                setPrintOn(row.collectionId);
                printPdf();
              }
            }}
            className={
              (row.collectionType || "").replace(/ /g, "-") + " invoices"
            }
          >
            {printOn != false && printOn == row.collectionId ? (
              <Sentry />
            ) : (
              row.collectionType
            )}
          </div>
        ),
        width: "200px",
        sortable: true,
      },
      {
        name: "İşlem Tarihi",
        selector: (row) => row.date,
        sortable: true,
        width: "170px",
      },
      {
        name: "Borç",
        selector: (row) =>
          row.currency != false ? row.currencyDebt : row.debt,
        cell: (row) => (
          <span style={{ color: "#341f21", fontWeight: "bold" }}>
            {row.currency != false ? row.currencyDebt : row.debt}
            {row.currency != false ? row.currencyUnit : "₺"}
          </span>
        ),
        width: "130px",
        sortable: true,
      },
      {
        name: "Alacak",
        selector: (row) =>
          row.currency != false ? row.currencyCredit : row.credit,
        cell: (row) => (
          <span style={{ color: "green", fontWeight: "bold" }}>
            {row.currency != false ? row.currencyCredit : row.credit}
            {row.currency != false ? row.currencyUnit : "₺"}
          </span>
        ),
        sortable: true,
        width: "130px",
      },
      {
        name: "Bakiye",
        selector: (row) =>
          (row.currency != false ? row.currencyCredit : row.credit)*1,
        cell: (row) => (
          <span style={{ color: "green", fontWeight: "bold" }}>
            <Currency>{row.balance}</Currency>
          </span>
        ),
        sortable: true,
        width: "130px",
      },
      {
        name: "Açıklama",
        selector: (row) => row.explanation,
        sortable: true,
      },
      {
        name: "Evrak No",
        selector: (row) => row.documentNumber,
        sortable: true,
        width: "160px",
      },
      {
        name: "",
        // selector: row => <> <Link to={"/siparisler/"+row.orderId} > <FontAwesomeIcon icon={faEye} /> </Link> </>,
        selector: (row) => (
          <div
            onClick={() => {
              if ((row.collectionType || "").indexOf("FATURA") != -1) {
                history.push("/faturalar/" + row.documentNumber);
                return;
              }
              if (printOn == false) {
                setPrintOn(row.collectionId);
                printPdf();
              }
            }}
            style={{ cursor: "pointer", fontSize: 20 }}
          >
            <FontAwesomeIcon icon={faSearchPlus} />
          </div>
        ),
        width: "60px",
        sortable: true,
      },
    ];

  const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  const [startDate, setStartDate] = useState(null);
  const [showStartDate, setShowStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showEndDate, setShowEndDate] = useState(null);
  const parseDate = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split(".").map(Number);
    const [hours, minutes, seconds] = timePart.split(":").map(Number);

    return new Date(year, month - 1, day, hours, minutes, seconds);
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value ? new Date(e.target.value) : null);
    setShowStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value ? new Date(e.target.value) : null);
    setShowEndDate(e.target.value);
  };

  const filteredItems = collections.filter((item) => {
    const itemDate = parseDate(item.date); // item.date'i Date objesine çeviriyoruz

    // Tarih aralığına göre filtreleme kontrolü
    let isWithinDateRange = true;
    if (startDate) {
      isWithinDateRange = itemDate >= startDate;
    }
    if (endDate) {
      isWithinDateRange = isWithinDateRange && itemDate <= endDate;
    }

    return (
      isWithinDateRange &&
      (item.date.toLowerCase().includes(filterText.toLowerCase()) ||
        (item.collectionId + "")
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        (item.collectionType + "")
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        (item.documentNumber + "")
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        (item.debt + "").toLowerCase().includes(filterText.toLowerCase()) ||
        (item.currencyDebt + "")
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        (item.credit + "").toLowerCase().includes(filterText.toLowerCase()) ||
        (item.currencyCredit + "")
          .toLowerCase()
          .includes(filterText.toLowerCase()))
    );
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        {printOn != false && printOn == true ? (
          <>
            <iframe
              src={"/yazdir/hareketlerim"}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: -1,
                height: 0,
                width: 0,
              }}
            />
          </>
        ) : (
          <></>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#eee",
            color: "#000",
            padding: 5,
            marginRight: 20,
          }}
        >
          {" "}
          Bakiye :{" "}
          <strong style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Currency>
              {JSON.parse(localStorage.getItem("customer")).balance}
            </Currency>{" "}
          </strong>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#eee",
            color: "#000",
            padding: 5,
            marginRight: 20,
          }}
        >
          {" "}
          Kullanılabilir Limit :{" "}
          <strong style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Currency>
              {JSON.parse(localStorage.getItem("customer")).limit}
            </Currency>
          </strong>
        </div>

        <button
          style={{
            color: "#000",
            border: "none",
            outline: "1px solid lightblue",
            marginRight: 20,
            height: 30,
            width: 70,
          }}
          onClick={() => {
            if (printOn == false) {
              setPrintOn(true);
              printPdf();
            }
          }}
        >
          {printOn == true ? <Sentry /> : "PDF"}
        </button>
        <input
          type="date"
          value={showStartDate}
          onChange={handleStartDateChange}
        />
        <input
          type="date"
          value={showEndDate}
          onChange={handleEndDateChange}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 0px 6px #000",
            padding: 5,
            justifyContent: "center",
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
          <input
            style={{ border: "none", outline: "none", textAlign: "right" }}
            placeholder="Ara"
            className="input-group"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
      </>
    );
  }, [filterText, printOn,resetPaginationToggle,startDate,endDate]);

  return (
    <>
      {printOn != false && printOn == true && loading == false ? (
        <>
          <iframe
            src={"yazdir/hareketler"}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: -1,
              height: 0,
              width: 0,
            }}
          />
        </>
      ) : (
        <></>
      )}
      {printOn != false && printOn != true && loading == false ? (
        <>
          <iframe
            src={"yazdir/hareket/" + printOn}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: -1,
              height: 0,
              width: 0,
            }}
          />
        </>
      ) : (
        <></>
      )}
      <Header />

      <div>
        <SlideProductContainer>
          {collections.length < 1 && loading ? (
            <>
              <div className="loader">
                <ContentLoader
                  speed={0.5}
                  width={2500}
                  height={160}
                  viewBox="0 0 2400 160"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="-25" y="0" rx="3" ry="3" width="2429" height="34" />
                  <rect x="-28" y="48" rx="3" ry="3" width="2437" height="35" />
                  <rect x="-29" y="96" rx="3" ry="3" width="2441" height="35" />
                </ContentLoader>
              </div>
              <div className="loader" style={{ marginTop: -100 }}>
                <ContentLoader
                  speed={0.5}
                  width={2500}
                  height={160}
                  viewBox="0 0 2400 160"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="-25" y="0" rx="3" ry="3" width="2429" height="34" />
                  <rect x="-28" y="48" rx="3" ry="3" width="2437" height="35" />
                  <rect x="-29" y="96" rx="3" ry="3" width="2441" height="35" />
                </ContentLoader>
              </div>
            </>
          ) : (
            <div style={{minHeight:'60vh'}}>
              <DataTable
                pagination
                subHeader
                noDataComponent={<div style={{height:'30vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:25,fontWeight:'bold'}}>Hareket kaydı bulunamadı</div>}
                subHeaderComponent={subHeaderComponentMemo}
                columns={columns}
                data={filteredItems}
              />
            </div>
          )}
        </SlideProductContainer>

        <SlideProductContainer>
          <h1
            style={{
              color: "rgba(255,10,0)",
              textAlign: "center",
              paddingTop: 40,
              fontSize: 30,
              marginBottom: 8,
              fontWeight: "bold",
            }}
            // onClick={() => history.push("/yeni-urunler")}
          >
            Kampanyalı Ürünler
          </h1>

          {discover.length > 0 ? (
            <SlideProducts items={discover} />
          ) : (
            <div className="loader">
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "20px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
            </div>
          )}
        </SlideProductContainer>
      </div>

      <BottomNav />

      <Footer />
    </>
  );
}

import React,{useState,useEffect} from 'react'

import {CartContainer} from '../../styles/Cart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTrashAlt, faShoppingCart, faShoppingBasket, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Currency } from 'react-intl-number-format/dist/index.js';
import { useCartRequests } from '../../helpers/CartRequests';
import { toast } from 'react-toastify';
import { setBasket } from '../stores/Cart';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import ListItem from './Item'
import { useOrderRequests } from '../../helpers/OrderRequests';
import { Sentry } from "react-activity";

export default function CartItems({cart,setLoader}) {

  const [total,setTotal]=useState(0)

  const {deleteCart}=useCartRequests()
  const {createOrder}=useOrderRequests()
  const history=useHistory()

  const dispatch=useDispatch()

  useEffect(() => {
    if(cart!=null && cart!=undefined){
      let totalCount=0
      cart.map((item)=>{
        totalCount+=(item.salePrice*item.quantity)
      })
      setTotal(totalCount)
    }
  }, [cart])
  
  const removeCart=()=>{
    
    const loader=toast.loading('Sepet siliniyor...',{position:toast.POSITION.BOTTOM_RIGHT,isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false})
    deleteCart().then(response=>{
      setLoader(true)
      dispatch(setBasket(null))
      toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
    })
    .catch(error=>{
      try {
        toast.update(loader, {
          render: error.response.data.message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        });
    
      } catch (error) {
        
      }
    })  

  }

  const createNewOrder=()=>{
    const loader=toast.loading('Sipariş oluşturuluyor...',{position:toast.POSITION.BOTTOM_RIGHT,isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false})
    createOrder(
      {
        paymentType:1,
        products:cart.map(x=>{
          return {productId:x.productId,quantity:x.quantity}
        })
      }
    ).then(response=>{
      removeCart()
      dispatch(setBasket(null))
      toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
    })
    .catch(error=>{
      toast.update(loader, { render: error.response.data.message, type: "error", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
    })  
  }

  const goHome=()=>{
    history.push('/')
  }

  return (
    <CartContainer>
      <div className="list">
        <ul>
          <li className="title">
            <h1>
              {" "}
              <FontAwesomeIcon icon={faShoppingCart} /> Sepetim{" "}
            </h1>
            {cart != null && cart.length > 0 ? (
              <div className="removeAll">
                <span
                  onClick={() => {
                    removeCart();
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </span>
              </div>
            ) : (
              <></>
            )}
          </li>
          {cart == null || cart.length < 1 ? (
            <li className="empty-cart">
              {cart == null ? (
                <div style={{width:400,display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <Sentry color={"#4f87ac"} size={230} />
                </div>
              ) : (
                <>
                  <h1>Sepetiniz Boş </h1>
                  <button className="payment" onClick={() => goHome()}>
                    Alışverişe Devam Et{" "}
                    <FontAwesomeIcon icon={faShoppingBasket} />
                  </button>
                </>
              )}
            </li>
          ) : (
            (cart != null ? cart : []).map((item) => {
              return (
                <ListItem
                  cart={cart}
                  item={item}
                  setLoader={setLoader}
                  key={"cart-list-" + item.slug}
                />
              );
            })
          )}
        </ul>
      </div>

      <div className="finally">
        <div className="calculate">
          <div className="head">
            Toplam {cart != null ? cart.length : 0} Ürün
          </div>
          <div className="total">
            {/* <CurrencyFormat lang='tr' value={total.toFixed(2)} displayType={'text'}  thousandSeparator={true}  /> */}
            <Currency>{total}</Currency>
          </div>
          {cart == null || cart.length < 1 ? (
            <button className="payment" onClick={() => goHome()}>
              Alışverişe Devam Et
            </button>
          ) : (
            <>
              {/* <button className='payment' onClick={createNewOrder}>Sipariş Oluştur</button> */}
              <button
                className="payment"
                onClick={() => history.push("/odeme-sayfasi")}
              >
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: 20 }} />
                Sepeti Onayla
              </button>
              <button
                className="payment"
                style={{ marginTop: 10 }}
                onClick={() => history.goBack()}
              >
                <FontAwesomeIcon
                  icon={faShoppingCart}
                  style={{ marginRight: 20 }}
                />
                Alışverişe Devam Et
              </button>
            </>
          )}
        </div>
      </div>
    </CartContainer>
  );
}

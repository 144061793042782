import React, { useState } from "react";
import Badge from "@mui/material/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { Currency } from "react-intl-number-format/dist/index.js";
import { useCartRequests } from "../../helpers/CartRequests";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setBasket } from "../stores/Cart";
import { useHistory } from "react-router-dom";
import { SlideProductItem } from "../../styles/Slide";

export default function Productproduct({ product }) {
  const dispatch = useDispatch();

  const history = useHistory();

  const { addCart, getCart } = useCartRequests();
  const [count, setCount] = useState(1);
  const { basket } = useSelector((state) => state.cart);
  const { login } = useSelector((state) => state.auth);

  const getBasket = () => {
    getCart().then((res) => {
      dispatch(setBasket(res.data.data));
    });
  };

  const addToBasket = async (product, count = 1) => {
    if (login == true) {
      const loader = toast.loading("Ürün Sepete Ekleniyor...", {
        position: toast.POSITION.BOTTOM_RIGHT,
        isLoading: true,
        closeOnClick: false,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        draggable: false,
      });
      addCart([{ id: product.id, count: count }])
        .then((res) => {
          getBasket();
          toast.update(loader, {
            render: res.data.message,
            type: "success",
            isLoading: false,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
          });
        })
        .catch((err) => {
          if (err.response != undefined && err.response.data != undefined) {
            toast.update(loader, {
              render: err.response.data.message,
              type: "error",
              isLoading: false,
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              pauseOnFocusLoss: false,
              draggable: true,
            });
          }
        });
    } else {
      history.push("/giris-yap");
    }
  };
  return (
    <SlideProductItem>
      <div className="image-box">
        <img
          onContextMenu={() => false}
          onClick={() => window.open("/urun/" + product.slug, "_blank")}
          // onClick={() => history.push("/urun/" + product.slug)}
          src={
            product.image == null
              ? "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image@2x.png"
              : product.image
          }
          alt={product.title}
        />
      </div>
      <h3> {product.title} </h3>
      {/* <div className={"in-stock "+product.stockText}> {product.stockText.toUpperCase()} </div> */}
      {basket != null &&
      basket.find((sub) => sub.id == product.id) != undefined ? (
        <div className="product-count">
          <Badge
            badgeContent={basket.find((sub) => sub.id == product.id).quantity}
            color="primary"
          >
            <FontAwesomeIcon icon={faShoppingCart} />
          </Badge>
        </div>
      ) : (
        <></>
      )}
      <div className="action">
        <div className="price">
          {/* <h5> <Currency>0</Currency> </h5> */}
          <div>
            {" "}
            {localStorage.getItem("sasatoken") != null ? (
              <Currency>{product.salePrice}</Currency>
            ) : (
              <p
                style={{
                  fontWeight: "normal",
                  fontSize: 9,
                  textAlign: "center",
                }}
              >
                Fiyatları görmek için{" "}
                <strong onClick={() => history.push("/giris-yap")}>
                  Giriş
                </strong>{" "}
                yapın.
              </p>
            )}{" "}
          </div>
        </div>
        <div className="basket">
          {localStorage.getItem("sasatoken") != null ? (
            <button
              style={
                {
                  // cursor: product.inStock ? "pointer" : "not-allowed",
                  // opacity: product.inStock ? "100%" : "10%",
                }
              }
              // disabled={!product.inStock}
              onClick={() => addToBasket(product)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          ) : (
            <></>
          )}
          {localStorage.getItem("sasatoken") != null ? (
            <div className="basket-container">
              <div className="buttons">
                <button
                  style={
                    {
                      // cursor: product.inStock ? "pointer" : "not-allowed",
                      // opacity: product.inStock ? "100%" : "10%",
                    }
                  }
                  // disabled={!product.inStock}
                  onClick={() => setCount(count - 1 == 0 ? 1 : count - 1)}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <input
                  type="text"
                  value={count}
                  onChange={(e) => setCount(e.target.value * 1)}
                />
                <button
                  style={
                    {
                      // cursor: product.inStock ? "pointer" : "not-allowed",
                      // opacity: product.inStock ? "100%" : "10%",
                    }
                  }
                  onClick={() => setCount(count + 1)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
              <div className="cartbtn">
                <button
                  onClick={() => addToBasket(product, count)}
                  // disabled={!product.inStock}
                >
                  {count} Adet Ekle
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </SlideProductItem>
  );
}

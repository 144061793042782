import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DetailContainer, SwapButton } from "../../styles/Product";
import ProductImage from "../Slider/ProductImage";
import { useCartRequests } from "../../helpers/CartRequests";
import { setBasket } from "../stores/Cart";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faTrash,
  faArrowLeft,faArrowRight,
  faTrashAlt,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import Lightbox, { ImagesListType } from "react-spring-lightbox";
import { Currency } from "react-intl-number-format/dist/index.js";

export default function ProductDetail({ data }) {
  const history = useHistory();
  const [productImages, setProductImages] = useState(
    data.images.map((x) => {
      return { src: x, loading: "lazy", alt: "deha" };
    })
  );

  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [openedBox, setOpenedBox] = useState(false);

  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < productImages.length &&
    setCurrentIndex(currentImageIndex + 1);

  const [count, setCount] = useState(1);
  const [currency, setCurrency] = useState(null);
  const dispatch = useDispatch();

  const { addCart, getCart } = useCartRequests();

  const getBasket = () => {
    getCart().then((res) => {
      dispatch(setBasket(res.data.data));
    });
  };

  const increment = async () => {
    if (!data.inStock && false) {
      return;
    }
    addCart([{ id: data.id, count: count }])
      .then((response) => {
        toast.success(response.data.message);
        setCount(1);
        getBasket();
      })
      .catch((error) => {
        if (error.response != undefined) {
          toast.error(error.response.data.message);
        }
      });
  };

  const handleChange = async (e) => {
    setCount(e.target.value);
  };

  const getCurrency = () => {
    axios({
      url: "https://finans.truncgil.com/v3/today.json",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    }).then((res) => {
      setCurrency(res.data);
      console.log("first", res.data);
    });
  };

  useEffect(() => {
    if (currency == null) {
      getCurrency();
    }
  }, [currency]);

  useEffect(() => {
    setProductImages(
      data.images.map((x) => {
        return { src: x, loading: "lazy", alt: "deha" };
      })
    );
  }, [data]);

  return (
    <DetailContainer>
      <Helmet>
        <title>{data.title}</title>
        <meta property="og:title" content={data.title} />
        <meta
          property="og:description"
          content={data.description + data.description2 + data.description3}
        />
        <meta
          property="og:image"
          content={
            data.images != null
              ? data.images[0]
              : "https://dehasoft.com.tr/images/LOGO-p-500.png" 
          }
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" /> 
      </Helmet>
      <div className="detail-header">
        <div className="detail-nav">
          <Link to={"/kategori/" + data.groupSlug}>{data.group}</Link>/
          <Link to={"/kategori/" + data.subGroupSlug}>{data.subGroup}</Link>
          {/* <Link to={"/kategori/" + data.subSubGroupSlug}>
            {data.subSubGroup}
          </Link> */}
        </div>

        {/* <div className="title">
          <h1 style={{ color: "#000" }}>{data.code}</h1> 
        </div> */}
      </div>

      <div className="product" style={{ position: "relative" }}>
        <div className="product-image" style={{ position: "relative" }}>
          {data.stickerImage != "" && (
            <img
              src={data.stickerImage + "?ordu=" + 5252}
              className="sticker"
              onClick={() => history.push("/kampanyali-urunler")}
            />
          )}
          <Lightbox
            isOpen={openedBox}
            onPrev={gotoPrevious}
            onNext={gotoNext}
            images={productImages}
            currentIndex={currentImageIndex}
            renderHeader={() => (
              <button
                onClick={() => setOpenedBox(false)}
                style={{
                  position: "fixed",
                  fontSize: 22,
                  fontWeight: "bold",
                  color: "#fff",
                  boxShadow: "0px 0px 19px #000",
                  outline: "none",
                  border: "none",
                  zIndex: 999,
                  padding: 10,
                  paddingLeft: 20,
                  paddingRight: 20,
                  right: 150,
                  width: 200,
                  background: "#341f21",
                }}
              >
                X
              </button>
            )}
            onClose={() => setOpenedBox(false)}
            renderNextButton={() => (
              <SwapButton onClick={gotoNext} className="image-swap-button">
                <FontAwesomeIcon icon={faArrowRight} />
              </SwapButton>
            )}
            renderPrevButton={() => (
              <SwapButton onClick={gotoPrevious} className="image-swap-button">
                <FontAwesomeIcon icon={faArrowLeft} />
              </SwapButton>
            )}
            style={{ background: "rgba(0,0,0,.8)" }}
            pageTransitionConfig={{
              from: { transform: "scale(0.75)", opacity: 0 },
              enter: { transform: "scale(1)", opacity: 1 },
              leave: { transform: "scale(0.75)", opacity: 0 },
              config: { mass: 1, tension: 320, friction: 32 },
            }}
          />
          <ProductImage
            openedBox={openedBox}
            currentImageIndex={currentImageIndex}
            setCurrentIndex={setCurrentIndex}
            setOpenedBox={setOpenedBox}
            items={data.images}
          />
        </div>

        <div
          className={
            "product-store " + (data.inStock == false && false ? "notstock" : "")
          }
        >
          <div className="product-stock">{data.stockText}</div>
          <div className="product-barcode">{data.code}</div>

          <div className="product-title" style={{ marginTop: 20 }}>
            {data.title}
          </div>

          <div className="product-colors"></div>

          <div className="price-list">
            {/* <div className="product-price adet">
              BİRİM FİYATI {data.price.toFixed(2)} {localStorage.getItem("currency")}
            </div> */}
            {data.salePrice != data.listPrice ? (
              <div
                className="product-price list-price"
                style={{ marginLeft: 20 }}
              >
                <Currency>{data.listPrice * count}</Currency>{" "}
              </div>
            ) : (
              <></>
            )}

            {localStorage.getItem("sasatoken") != null ? (
              <div
                className="product-price"
                style={{ marginLeft: 20, marginTop: 60 }}
              >
                <Currency>{data.salePrice * count}</Currency>{" "}
              </div>
            ) : (
              <>
                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: 12,
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  Fiyatları görmek için{" "}
                  <strong onClick={() => history.push("/giris-yap")}>
                    Giriş
                  </strong>{" "}
                  yapın.
                </p>
                <Button
                  onClick={() => setCount(count * 1 + 1)}
                  style={{color:'green'}}
                >
                  Giriş Yap
                </Button>
              </>
            )}
          </div>

          {localStorage.getItem("sasatoken") != null && (
            <div style={{ marginTop: -60 }}>
              <div className="counter">
                <Button
                  variant="contain"
                  onClick={() =>
                    setCount(count * 1 - 1 < 1 ? 1 : count * 1 - 1)
                  }
                  className={`buttons ${data.quantity == 1 ? "delete" : ""}`}
                >
                  <FontAwesomeIcon
                    icon={data.quantity == 1 ? faTrashAlt : faMinus}
                  />{" "}
                </Button>

                <input type="text" onChange={handleChange} value={count} />

                <Button
                  variant="contain"
                  onClick={() => setCount(count * 1 + 1)}
                  className="buttons"
                >
                  <FontAwesomeIcon icon={faPlus} />{" "}
                </Button>
              </div>

              <div className="add-basket">
                <Button onClick={increment} variant="contain">
                  {" "}
                  <span>
                    <FontAwesomeIcon icon={faShoppingCart} />
                  </span>{" "}
                  {count} {data.usedUnit}{" "}
                  {data.usedUnit != "ADET"
                    ? "(" + data.unit2coefficient + ")"
                    : ""}{" "}
                  Sepete Ekle
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="product-information">
        <h3 style={{ marginBottom: 50, fontWeight: "bold" }}>
          Ürün Açıklaması
        </h3>

        <div
          dangerouslySetInnerHTML={{
            __html: (data.eTradeDescription || "")
              .replace("<[OZELLIK]>", "")
              .replace("<[ACIKLAMA]>", "")
              .replace("<[TARIF]>", "")
              .replace("<[SURELER]>", ""),
          }}
          className="html-desc"
        />

        <p>
          {data.description}. {data.description2}. {data.description3}.{" "}
          {data.description4}
        </p>

        <div className="property"></div>
      </div>
    </DetailContainer>
  );
}

import React, { useState, useEffect } from "react";

import { CartContainer } from "../styles/Cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faShoppingCart,
  faShoppingBasket,
  faCreditCard,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { Currency } from "react-intl-number-format/dist/index.js";
import { useCartRequests } from "../helpers/CartRequests";
import { toast } from "react-toastify";
import { setBasket } from "./stores/Cart";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ListItem from "./Cart/Item";
import { useOrderRequests } from "../helpers/OrderRequests";
import { CopyToClipboard } from "react-copy-to-clipboard";
import mainLogo from "../mainlogo.png";
export default function Payment({ total , setTotal}) {

  

  const [selectedTab, setSelectedTab] = useState("card");
  const [paymentError, setPaymentError] = useState(null);
  const [paymentForm, setPaymentForm] = useState(null);

  const [bankAccounts, setBankAccounts] = useState(null);
  const [copied, setCopied] = useState(false);

  const { createOrder, getBankAccounts, createPaymentForm } =
    useOrderRequests();
  const history = useHistory();

  const dispatch = useDispatch();

  const getPaymentForm = async () => {
    setPaymentError(null);
    createPaymentForm({ type: 1,amount:total*1 })
      .then((res) => {
        setPaymentForm(atob(res.data.data.htmlContent));
        console.log(paymentForm);
      })
      .catch((err) => {
        setPaymentError(
          "Beklenmedik bir hata oluştu. Tekrar denemek için aşağıdaki butona tıklayabilirsiniz."
        );
      });
  };

  useEffect(() => {
    if (selectedTab == "card") {
      getPaymentForm();
    }
  }, [selectedTab]);


  useEffect(() => {
    if (bankAccounts == null) {
      getBankAccounts().then((res) => {
        setBankAccounts(res.data.data);
      });
    }
  }, [bankAccounts]);

  

  const createNewOrder = () => {
    const loader = toast.loading("Tahsilatınız oluşturuluyor...", {
      position: toast.POSITION.BOTTOM_RIGHT,
      isLoading: true,
      closeOnClick: false,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
      draggable: false,
    });
    
  };

  const goHome = () => {
    history.push("/");
  };

  const handleCopyIban = (iban = "IBANBULUNAMADI") => {
    const loader = toast.loading("IBAN numarası kopyalanıyor...", {
      position: toast.POSITION.BOTTOM_RIGHT,
      isLoading: true,
      closeOnClick: false,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
      draggable: false,
    });
    toast.update(loader, {
      render: "IBAN numarası başarı ile kopyalandı",
      type: "success",
      isLoading: false,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      pauseOnFocusLoss: false,
      draggable: true,
    });
    setCopied(true);
  };

  const getPaymentButton = () => {
      return;
    if (selectedTab == "transfer") {
      return (
        <button className="payment" onClick={createNewOrder}>
          Tahsilat Oluştur
        </button>
      );
    } else {
      return
      return (
        <button className="payment" onClick={() => alert("asd")}>
          Ödemeye Geç
        </button>
      );
    }
  };

  return (
    <CartContainer>
      <div className="list">
        <ul style={{ width: "95%" }}>
          <li className="title">
            <h1>
              {" "}
              <FontAwesomeIcon icon={faCreditCard} /> Ödeme Yöntemleri{" "}
            </h1>
          </li>

          <div className="payment-tabs">
            <div
              onClick={() => {
                //setSelectedTab("transfer")
              }}
              style={{ color: "#333" }}
              className={
                "payment-tab " + (selectedTab == "transfer" ? " active" : "")
              }
            >
              Havale/Eft
            </div>
            <div
              onClick={() => setSelectedTab("card")}
              className={
                "payment-tab " + (selectedTab == "card" ? " active" : "")
              }
            >
              Kredi Kartı
            </div>
          </div>
          <div
            className="bank-cards"
            style={{ display: selectedTab == "transfer" ? "block" : "none" }}
          >
            {bankAccounts != null ? (
              bankAccounts.map((x) => {
                return (
                  <CopyToClipboard
                    key={x.iban}
                    text={x.iban}
                    onCopy={handleCopyIban}
                  >
                    <div className="bank-card">
                      <h1>{x.bankTitle}</h1>
                      <h2>
                        Para Birimi : {x.accountTitle} ({x.accountType})
                      </h2>
                      <h2>Hesap Adı : {x.bankAddress} </h2>
                      <div className="iban">
                        {x.iban}
                        <FontAwesomeIcon icon={faCopy} />
                      </div>
                    </div>
                  </CopyToClipboard>
                );
              })
            ) : (
              <></>
            )}
          </div>

          <div
            className="payment-card"
            style={{ display: selectedTab == "card" ? "block" : "none" }}
          >
            {paymentError != null && paymentForm != null ? (
              <div className="paymentError">
                {paymentError}
                <button onClick={() => getPaymentForm()}>Tekrar Dene</button>
              </div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: paymentForm }} />
            )}
          </div>
        </ul>
      </div>

      <div className="finally">
        <div className="prod-list">
          <img src={mainLogo} />
        </div>

        <>
          <div className="calculate">
            <div className="head">Tahsilat Tutarı :</div>
            <div className="total">
              {/* <CurrencyFormat lang='tr' value={total.toFixed(2)} displayType={'text'}  thousandSeparator={true}  /> */}
              <>{total} ₺</>
            </div>
            <button className="payment" onClick={()=>setTotal(null)}>
              Geri Dön
            </button>
          </div>
        </>
      </div>
    </CartContainer>
  );
}

import styled from "styled-components";
import { rgb, rgba } from "polished";

const CartContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  .payment-card {
    color: #000;
    min-height: 110vh;
    iframe {
      min-height: 110vh;
      width: 100%;
      height: 100%;
    }
    @media only screen and (max-width: 1140px) {
      iframe {
        min-height: 300vh;
      }
    }
    .paymentError {
      padding: 20px;
      font-weight: bold;
      font-size: 22px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 240px;
      box-shadow: 0px 0px 10px #e53935;
      background: #ffcdd2;
      button {
        margin-top: 30px;
        border: none;
        outline: none;
        background: ${({ theme }) => theme.altHeader};
        color: #fff;
        padding: 10px 5%;
        font-weight: bold;
        transition: all 1s;
        &:hover {
          transform: scale(1.1) rotate(1deg);
          background: #e57373;
        }
      }
    }
  }
  .bank-cards {
    color: #000;
  }
  .bank-card {
    width: 100%;
    padding: 30px 5px;
    border-radius: 5px;
    background: rgba(25, 71, 123, 0.3);
    margin-top: 20px;
    &:hover {
      background: ${({ theme }) => theme.altHeader};
      color: #fff;
      cursor: pointer;
    }
    h1 {
      font-size: 17px;
      font-weight: bold;
    }
    h2 {
      font-size: 14px;
    }
    div {
      display: flex;
      margin-top: 5px;
      font-size:13px;
      justify-content: space-between;
      align-items: center;
    }
  }
  .payment-tabs {
    width: 100%;
    display: flex;

    .payment-tab {
      color: #000;
      padding: 7px 30px;
      margin: 3px;
      font-weight: bold;
      cursor: pointer;
    }
    .payment-tab.active {
      background: ${({ theme }) => theme.altHeader};
      color: #fff;
    }
  }
  .list {
    width: 75%;
    display: flex;
    justify-content: flex-end;
    position: relative;

    ul {
      display: block;
      box-shadow: 0px 0px 16px 0px #ddd;
      width: 90%;
      padding: 25px;
      border-radius: 15px;
      position: relative;
      .title {
        background-color: ${({ theme }) => theme.primary};
        h1 {
          color: #333;
          font-size: 24px;
          font-weight: bold;
        }
        .removeAll {
          right: 0;
          top: 0;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          background-color: ${({ theme }) => theme.primary};
          cursor: pointer;
          span {
            background-color: ${({ theme }) => theme.altHeader};
            transition: 500ms all;
            padding: 5px 10px;
            font-size: 22px;
            border-radius: 100%;
            &:hover {
              color: #fff;
              background-color: red;
            }
          }
          font-size: 15px;
        }
      }
      .empty-cart {
        h1 {
          font-size: 27px;
          text-align: center;
          padding-top: 60px;
          font-weight: bold;
          color: #333;
          width: 100%;
        }
        display: flex;
        justify-content: center;

        button {
          padding: 10px;
          margin-top: 5px;
          border-radius: 10px;
          font-size: 16px;
          font-weight: bold;
          transition: 600ms all;
          background-color: ${({ theme }) => rgba(theme.mainThemeColor, 0.5)};
          &:hover {
            background-color: ${({ theme }) => rgba(theme.mainThemeColor, 0.7)};
          }
          color: #fff;
          border: 0;
          outline: 0;
          cursor: pointer;
        }
      }
      li {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        &:not(:last-child) {
          padding-bottom: 15px;
          margin-bottom: 15px;

          border-bottom: 1px solid #eee;
        }
        @media only screen and (max-width: 1140px) {
          div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: auto;
            margin-top: 15px;
            img {
              width: 240px !important;
              height: 240px !important;
              margin-bottom: 40px;
            }
          }
        }
        .buttons {
          @media only screen and (max-width: 1140px) {
            width: 50%;
          }
          border-radius: 100%;
          font-size: 10px;
          margin-bottom: 15px;
          margin-top: 15px;
        }
        .delete {
          background-color: rgba(255, 0, 0, 0.5);
          &:hover {
            background-color: rgba(255, 0, 0, 0.7);
          }
        }
        .removeItem {
          position: absolute;
          bottom: 0px;
          right: -25px;
          height: 0px;
          width: 0px;
          border-bottom-left-radius: 0%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          color: #fff;
          font-size: 0px;
          background-color: rgba(255, 0, 0, 0.5);
          transition: 200ms all;
          cursor: pointer;
        }
        &:hover {
          .removeItem {
            font-size: 16px;
            height: 40px;
            width: 40px;
            border-top-left-radius: 100%;
          }
        }
        h3 {
          font-size: 17px;
          font-weight: bold;
          color: red;
        }
        .item-name {
          width: calc(100% - 300px);
          h1 {
            padding-top: 30px;
            font-size: 17px;
            font-weight: bold;
            color: #000;
            padding-left: 20px;
          }
          h2 {
            position: absolute;
            bottom: 0;
            font-size: 25px;
            font-weight: bold;
            color: red;
          }

          @media only screen and (max-width: 1140px) {
            flex-direction: column;
          }
        }
        div:last-child {
          min-width: 130px;
          max-width: 130px;
          display: flex;
          @media only screen and (max-width: 1140px) {
            min-width: 100%;
            max-width: 100%;
            .buttons {
              height: auto !important;
            }
            input {
              width: 70% !important;
              margin: 0 !important;
            }
          }
          justify-content: space-around;
          align-items: center;
          input {
            width: 55%;
            border: 0;
            outline: 0;
            margin: 0px 10px;
            padding: 10px;
            background-color: #eee;
            border-radius: 10%;
            font-size: 14px;
            font-weight: bold;
          }
          .buttons {
            width: 40px;
            height: 40px;
          }
        }
        div:first-child {
          img {
            border: 1px solid #333;
            height: 130px;
            width: 130px;
            border-radius: 5%;
          }
        }
      }
      @media only screen and (max-width: 1140px) {
        div {
          width: 100%;
        }
      }
    }
  }

  .finally {
    img {
      width: 100%;
    }
    width: 20%;
    margin-left: 2%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .prod-list {
      margin-top: 40px;
      padding: 10px 3px;
      margin-bottom: 20px;
      color: #000;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      background-color: ${({ theme }) => rgba(theme.altHeader, 0.021)};
      border: 1px solid #1111;
      box-shadow: 8px 8px 0px 0px #1111;
      flex-direction: column;
      width: 100%;
      .prod-item:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.altHeader};
      }
      .prod-item {
        width: 100%;
        padding: 5px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          max-width: 45%;
          min-width: 45%;
          width: 45%;
        }
        .price {
          max-width: 45%;
          min-width: 45%;
          width: 45%;
        }
        .qty {
          max-width: 10%;
          min-width: 10%;
          width: 10%;
        }
      }
    }
    .calculate {
      padding: 25px;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      background-color: ${({ theme }) => theme.altHeader};
      box-shadow: 8px 8px 0px 0px #000;
      flex-direction: column;
      width: 100%;

      .head {
        font-size: 20px;
        font-weight: bold;
        font-family: "Overpass", sans-serif !important;
        margin-bottom: 15px;
      }
      .total {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
        color: #fff;
        font-family: "Comfortaa", sans-serif !important;
      }
      button {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: bold;
        transition: 600ms all;
        background-color: ${({ theme }) => rgba(theme.mainThemeColor, 1)};
        color: #000;
        &:hover {
          color: #fff;
          background-color: ${({ theme }) => rgba(theme.mainThemeColor, 0.4)};
        }
        border: 0;
        outline: 0;
        cursor: pointer;
      }
    }
  }
  @media only screen and (max-width: 1140px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .finally {
      width: 100%;
    }
    .calculate {
      width: 80%;
    }
    .list {
      width: 100%;
    }
  }
`;

export { CartContainer };

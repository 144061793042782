import { createSlice } from "@reduxjs/toolkit";
export const cart = createSlice({
  name:'cart',
  initialState:{
    basket:localStorage.getItem('cart')!=null?JSON.parse(localStorage.getItem('cart')):null,
  },
  reducers:{
    setBasket: (state,action) => {
      if(typeof action.payload=='array' || typeof action.payload=='object'){
        state.basket=action.payload
        localStorage.setItem('cart',JSON.stringify(action.payload))
      }
    },
    emptyCart: (state,action) => {
      state.basket=null
      localStorage.removeItem('cart')
    },
  }
})

export const {setBasket,itemIncrement,emptyCart} = cart.actions
export default cart.reducer
import axios from "axios";

export const useOrderRequests = () => {
  const getBankAccounts = async () => {
    return axios({
      method: "get",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_GET_BANK_ACCOUNTS
      }`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_GET_BANK_ACCOUNTS
        }`,
        Authorization: `${localStorage.getItem("sasatoken")}`,
      },
    });
  };
  const getOrders = async () => {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_API + process.env.REACT_APP_ORDERS}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_ORDERS
        }`,
        Authorization: `${localStorage.getItem("sasatoken")}`,
      },
    });
  };

  const getCollections = async () => {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_API + process.env.REACT_APP_COLLECTIONS}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_COLLECTIONS
        }`,
        Authorization: `${localStorage.getItem("sasatoken")}`,
      },
    });
  };

  const getCollection = async (id) => {
    return axios({
      method: "get",
      url:
        `${
          process.env.REACT_APP_API + process.env.REACT_APP_COLLECTION
        }?collectionId=` + id,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_COLLECTION
        }`,
        Authorization: `${localStorage.getItem("sasatoken")}`,
      },
    });
  };

  const getInvoices = async () => {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_API + process.env.REACT_APP_GET_INVOICES}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_GET_INVOICES
        }`,
        Authorization: `${localStorage.getItem("sasatoken")}`,
      },
    });
  };

  const getInvoice = async (id) => {
    return axios({
      method: "get",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_GET_INVOICE
      }?invoiceNumber=${id}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_GET_INVOICE
        }`,
        Authorization: `${localStorage.getItem("sasatoken")}`,
      },
    });
  };

  const getOrder = async (id) => {
    return axios({
      method: "get",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_ORDER
      }?orderId=${id}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_ORDERS
        }`,
        Authorization: `${localStorage.getItem("sasatoken")}`,
      },
    });
  };

  const createOrder = async (order) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_API + process.env.REACT_APP_CREATE_ORDER}`,
      data: order,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_CRAETE_ORDERS
        }`,
        Authorization: `${localStorage.getItem("sasatoken")}`,
      },
    });
  };

  const getProductReports = async (startDate, endDate) => {
    return axios({
      method: "get",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_PRODUCT_REPORTS
      }?FirstDate=${startDate}&LastDate=${endDate}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_PRODUCT_REPORTS
        }`,
        Authorization: `${localStorage.getItem("sasatoken")}`,
      },
    });
  };

  const createPaymentForm = async (data = { type: 1, amount: 1 }) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_API + process.env.REACT_APP_GET_PAYMENT}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_PRODUCT_REPORTS
        }`,
        Authorization: `${localStorage.getItem("sasatoken")}`,
      },
    });
  };

  return {
    getBankAccounts,
    getOrders,
    getOrder,
    createOrder,
    getCollections,
    getCollection,
    getProductReports,
    createPaymentForm,
    getInvoices,
    getInvoice,
  };
};

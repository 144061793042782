import React from "react";
import "./index.css";
import App from "./App";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import store from "./components/stores/index";
import { IntlProvider } from "react-intl-number-format/dist/index.js";
import { BrowserRouter } from "react-router-dom";
import { StrictMode } from "react";

const rootElement = document.getElementById("root");
const intlConfig = {
  locale:
    localStorage.getItem("currency") == "TL"
      ? "tr-TR"
      : localStorage.getItem("currency") == "$"
      ? "en-US"
      : "tr-TR",
  options: {
    currency:
      localStorage.getItem("currency") == "TL"
        ? "TRY"
        : localStorage.getItem("currency") == "$"
        ? "USD"
        : "TRY",
    style: "currency",
    maximumFractionDigits: 2,
  },
};

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <Provider store={store}>
      <IntlProvider config={intlConfig}>
        <BrowserRouter>
          <StrictMode>
            <App />
          </StrictMode>
        </BrowserRouter>
      </IntlProvider>
    </Provider>,
    rootElement
  );
} else {
  ReactDOM.render(
    <Provider store={store}>
      <IntlProvider config={intlConfig}>
        <BrowserRouter>
          <StrictMode>
            <App />
          </StrictMode>
        </BrowserRouter>
      </IntlProvider>
    </Provider>,
    rootElement
  );
}

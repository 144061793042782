import React, { useEffect, useState } from "react";
import { PrintContainer } from "../../styles/Print";
import { useOrderRequests } from "../../helpers/OrderRequests";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
export default function PrintOrders() {
  const { id } = useParams();

  const [invoice, setInvoice] = useState([]);
  const [total, setTotal] = useState(0);
  const { getInvoice } = useOrderRequests();
  useEffect(() => {
    if (invoice.length < 1) {
      getInvoice(id).then(async (response) => {
        setInvoice(response.data.data);
        let unit = "";

        setTimeout(() => {
          window.print();
        }, 2000);
      });
    }
  }, [invoice]);

  return (
    <PrintContainer>
      <h1>Fatura Detayı</h1>

      {invoice != [] && invoice.invoice != undefined ? (
        <>
          <div className="head">
            <div className="leftHead">
              <div>
                <span>Tarih</span>
                <span>:</span>
                <span>{invoice.invoice.date}</span>
              </div>

              <div>
                <span>Sipariş No</span>
                <span>:</span>
                <span>{invoice.invoice.invoiceCode}</span>
              </div>

              <div>
                <span>Durum</span>
                <span>:</span>
                <span>{invoice.invoice.status}</span>
              </div>
            </div>

            <div className="rightHead">
              <h4>{JSON.parse(localStorage.getItem("customer")).title}</h4>
              <img
                onContextMenu={() => false}
                src={invoice.invoice.image}
                height={70}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <ul>
        <li>
          <div>Stok Kodu</div>
          <div>Barkod</div>
          <div>Miktar</div>
          <div>Kdv Oranı</div>
          <div>Tutar</div>
          <div>Kdv Dahil Tutar</div>
        </li>
        {(invoice != [] && invoice.items != undefined ? invoice.items : []).map(
          (x) => {
            return (
              <li>
                <div>{x.productCode}</div>
                <div>{x.barcode}</div>
                <div>
                  {x.quantity} {x.unit}
                </div>
                <div>%{x.vatRate}</div>
                <div>₺{x.totalPrice.toFixed(2)}</div>
                <div>₺{x.totalPriceWithVat.toFixed(2)}</div>
              </li>
            );
          }
        )}
        <li>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div>
            Toplam :
            ₺
            {invoice.invoice != undefined
              ? invoice.invoice.totalPrice
              : ""}{" "}
          </div>
        </li>
      </ul>
    </PrintContainer>
  );
}

import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  faBlenderPhone,
  faEnvelope,
  faGlobe,
  faMap,
  faMobileAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Link } from "react-router-dom";
import { FooterContainer } from "../../styles/Header";
import logo from "../../mainlogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function Footer() {
  return (
    <FooterContainer>
      <div className="copyright">
        <div className="logos">
          <img src={logo} alt="logo" />
        </div>
        <div className="main">
          {/* <div onClick={() => window.open("tel:+902126566006")}>
            <FontAwesomeIcon icon={faPhone} /> +90 212 656 60 06
          </div>
          <div onClick={() => window.open("tel:+905496566006")}>
            <FontAwesomeIcon icon={faMobileAlt} /> +90 549 656 60 06
          </div> */}
          <div onClick={() => window.open("mailto:info@b2b.sasagsm.com")}>
            <FontAwesomeIcon icon={faEnvelope} /> info@b2b.sasagsm.com
          </div>
        </div>
        <div
          onClick={() => window.open()}
          style={{
            marginTop: 10,
            marginBottom: 10,
            fontSize: 15,
            cursor: "pointer",
          }}
        >
          {/* Göztepe mah. 2374. sokak <br /> No : 1{" "}
          <strong>Bağcılar/İstanbul</strong> */}
        </div>
        <div> Tüm hakları sakldırı &copy; 2023</div>
        <div>
          <img
            src="https://dehasoft.com.tr/dehasoftblack.png"
            className="dehasoft-img"
            onClick={() => window.open("https://dehasoft.com.tr/", "_blank")}
          />
        </div>
      </div>
    </FooterContainer>
  );
}

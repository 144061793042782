import React, { useEffect, useState } from "react";
import { PrintContainer } from "../../styles/Print";
import { useOrderRequests } from "../../helpers/OrderRequests";
export default function PrintInvoices() {
  const [invoices, setInvoices] = useState(null);
  const [total, setTotal] = useState(0);
  const { getInvoices } = useOrderRequests();
  useEffect(() => {
    if (invoices == null) {
      getInvoices().then(async (response) => {
        setInvoices(response.data.data);
        let totals = 0;
        let unit = "";
        const seter = await response.data.data.map((x) => {
          unit = x.currencyUnit;
          totals += x.totalCurrencyPrice;
        });
        setTotal(unit + totals);

        setTimeout(() => {
          window.print();
        }, 300);
      });
    }
  }, [invoices]);

  return (
    <PrintContainer>
      <h1>Fatura Listesi</h1>
      <ul>
        <li>
          <div>Kod</div>
          <div>Ad</div>
          <div>Tarih</div>
          <div>Durum</div>
          <div>Tutar</div>
        </li>
        {(invoices != null ? invoices : []).map((x) => {
          return (
            <li>
              <div style={{ fontSize: 10 }}>{x.invoiceCode}</div>
              <div style={{ fontSize: 8 }}>{x.detail}</div>
              <div style={{ fontSize: 10 }}>{x.date}</div>
              <div style={{ fontSize: 10 }}>{x.status}</div>
              <div style={{ fontSize: 10 }}>₺{x.totalPrice}</div>
            </li>
          );
        })}
      </ul>
    </PrintContainer>
  );
}

import { faTimes,faTimesCircle,faArrowDown, faList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import React,{useState,useLayoutEffect} from 'react'
import { useProductRequests } from '../../helpers/ProductRequests';
import { useHistory } from 'react-router-dom';
import { HeaderAltMobile } from '../../styles/Header';

export default function PopUp({open,setOpen}) {
  const { getCategories, getBrands } = useProductRequests();

  const [categories, setCategories] = useState([]);
  const [selected,setSelected] = useState(false);

  const history=useHistory()
  

  useLayoutEffect(() => {
    
    if(categories.length === 0){
      getBrands().then((response)=>{
        setCategories(response.data.data)
      }).catch((error)=>{
        console.log("error",error)
      })
    }
    
  }, [])

  const closePopUp=(rt)=>{
    setOpen(false)
    setSelected(null)
    history.push(rt)
  }

  return (
    <HeaderAltMobile>
      <button className="close-btn" onClick={() => setOpen(false)}>
        <FontAwesomeIcon icon={faTimesCircle} />
      </button>
      <div style={{ marginTop: 70 }}>
        <h1 style={{color:'#000',marginLeft:40}}>Kategoriler</h1>
        <ul>
          {categories != null &&
          categories != undefined &&
          categories.length > 0 ? (
            <>
              {categories.map((category, index) => {
                return (
                  <li key={"ctgm" + category.slug} className={category.name}>
                    <button>
                      <div>
                        <span
                          onClick={() =>
                            closePopUp("/kategori/" + category.slug)
                            
                          }
                          style={{ width: "100%" }}
                        >
                          {category.name}
                        </span>
                        <FontAwesomeIcon
                          onClick={() =>
                            setSelected(
                              selected == category.slug ? null : category.slug
                            )
                          }
                          style={{
                            width: 200,
                            textAlign: "right",
                            minHeight: 20,
                          }}
                          icon={faList}
                        />
                      </div>
                    </button>
                  
                  </li>
                );
              })}
            </>
          ) : (
            <>
              {/* <CategoryLoader head={"li"} count={10} /> */}
            </>
          )}

          
          {/* <li>
          <button
            onClick={() => history.push({ pathname: "/kampanyali-urunler" })}
          >
            <span>Kampanya</span>
          </button>
        </li> */}
        </ul>
      </div>
    </HeaderAltMobile>
  );
}

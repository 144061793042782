import React from "react";
import { AuthPage } from "../../styles/Auth";
import { Link } from "react-router-dom";
import { Tab } from "@headlessui/react";
import RegisterForm from "../../components/Forms/RegisterForm";

import Footer from "../../components/Navigation/Footer";

import CookieConsent, { Cookies } from "react-cookie-consent";
import mainlogowhite from "../../mainlogo.png";
export default function Contact() {
  return (
    <AuthPage>
      <CookieConsent
        location="bottom"
        buttonText="Tümünü Kabul Et"
        cookieName="dehasoft"
        style={{
          background: "#DB2C1D",
          minHeight: "10vh",
          alignItems: "center",
        }}
        buttonStyle={{ color: "#000", fontSize: "15px" }}
        expires={250}
      >
        Bu web sitesi, kullanıcı deneyimini geliştirmek için tanımlama
        bilgilerini kullanır.{" "}
        <span style={{ fontSize: "10px" }}> &copy; dehasoft 2023</span>
      </CookieConsent>
      <Link to="/" className="logo">
        <img
          onContextMenu={() => false}
          height={420}
          style={{ marginTop: -190 }}
          src={mainlogowhite}
        />
      </Link>

      <RegisterForm />

      <Footer/>
    </AuthPage>
  );
}

import React from 'react'
import { CategoryBoxItems } from '../../styles/Content'
import { useHistory } from 'react-router-dom';

export default function CategoryBox({categories}) {
  const history=useHistory()
  return <CategoryBoxItems>
    {categories.map((category) => (
      <div key={category.slug} className='category-item' onClick={()=>history.push(`/kategori/${category.slug}`)} >
        <img src={"https://entegroid.com/sasagsm/marka/"+category.slug+".jpg"} />
        <span>{category.name}</span>
      </div>
    ))}
  </CategoryBoxItems>;
}

import React,{useState,useEffect} from 'react'
import axios from 'axios'
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import { useProductRequests } from '../helpers/ProductRequests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPhone, faPlus } from '@fortawesome/free-solid-svg-icons';
import logo from '../mainlogot.png'
import { Helmet } from 'react-helmet';
import useWindowDimensions from '../components/useWindowDimensions';
import { Sentry } from 'react-activity';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
export default function PriceList() {
  const { getCategories } = useProductRequests();
  const [open, setOpen] = useState("");
  const [subOpen, setSubOpen] = useState("");
  const [filter, setFilter] = useState("");

  const [data,setData]=useState(null)
  const [categories, setCategories] = useState(null);
  const getData=async(page=1)=>{
    getCategories().then((res) => {
      setCategories(res.data.data.sort((a, b) => (a.name > b.name ? 1 : -1)));
    });
    axios({
      url: process.env.REACT_APP_API + "api/Product/GetProductCatalog",
      method:'GET',
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    }).then((res) => {
      setData(
        res.data.sort((a, b) =>
          a.Title > b.Title ? 1 : b.Title > a.Title ? -1 : 0
        )
      );
      
    });
  }
 

  const filteredProducts=(category)=>{
    
      return data.filter((x) => {
        return x.SubGroupId == category;
      });
    
  }
  const filterdData=()=>{
    if (data == null || categories == null) {
      return [];
    } else {
      return data
        // .filter((x) => x.SalePrice != null && x.SalePrice > 0)
        .filter((x) => {
          return x.Title.toLowerCase().includes(filter.toLowerCase());
        });
    }
  }

  
  useEffect(()=>{
    if(data==null){
      getData();
    }
 
  },[])
  const width=useWindowDimensions().width
  return (
    <div style={{ width: width < 1200 ? width : 1200, margin: "auto" }}>
      <Helmet>
        <title>Fiyat Listesi</title>
      </Helmet>
      <div
        style={{
          display: "flex",
          color: "#000",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          boxSizing: "border-box",
          padding: 16,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={logo} style={{ maxHeight: 80 }} />
        </div>
        <div >
          <h4>Online Fiyat Listesi</h4>
          <div style={{ display: "flex",marginBottom:10,marginTop:10, alignItems: "center",cursor:'pointer',justifyContent:'flex-end' }} onClick={() => window.open("tel:+902125224565")}>
            <FontAwesomeIcon icon={faPhone} />
            <span style={{ marginLeft: 10 }}>0 212 522 45 65</span>
          </div>
          <div style={{ display: "flex", alignItems: "center",cursor:'pointer',justifyContent:'flex-end' }} onClick={() => window.open("https://wa.me/+9053548384565")}>
            <FontAwesomeIcon icon={faWhatsapp} />
            <span style={{ marginLeft: 10 }}>0 534 838 45 65</span>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", boxSizing: "border-box" }}>
        <input
          type="text"
          placeholder="Ara..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          style={{
            width: "100%",
            border: 0,
            outline: 0,
            padding: 10,
            boxShadow:
              "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)",
          }}
        />
      </div>
      {categories != null && filterdData(data) != null ? (
        categories
          .filter((x) => {
            if (
              x.subGroups.length > 0 &&
              x.subGroups.filter(
                (y) =>
                  filterdData(data).filter((z) => z.SubGroupId == y.id).length >
                  0
              ).length > 0
            ) {
              return x;
            }
          })
          .map((x) => (
            <Accordion
              expanded={open === x.name}
              onChange={() => setOpen(open == x.name ? "" : x.name)}
              key={x._id}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={{ width: 60 }}>
                  <FontAwesomeIcon icon={open === x.name ? faMinus : faPlus} />{" "}
                </div>
                <div>{x.name}</div>
              </AccordionSummary>
              <AccordionDetails>
                {x.subGroups
                  ?.filter(
                    (y) =>
                      filterdData(data).filter((z) => z.SubGroupId == y.id)
                        .length > 0
                  )
                  .map((y) => {
                    return (
                      <Accordion
                        expanded={subOpen === y.name}
                        onChange={() =>
                          setSubOpen(subOpen == y.name ? "" : y.name)
                        }
                        key={x._id}
                      >
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div style={{ width: 60 }}>
                            <FontAwesomeIcon
                              icon={subOpen === y.name ? faMinus : faPlus}
                            />{" "}
                          </div>
                          <div>{y.name}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                          {filteredProducts(y.id).map((z, index) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  backgroundColor:
                                    index % 2 == 0 ? "#fff" : "#f8f8f8",
                                  padding: 10,
                                  boxSizing: "border-box",
                                }}
                                onClick={() =>
                                  window.open(`/urun/${z.slug}`, "_blank")
                                }
                              >
                                <div style={{ width: "calc(100% - 100px)" }}>
                                  {z.Title}
                                </div>
                                <div style={{ width: 100 }}>${z.SalePrice}</div>
                              </div>
                            );
                          })}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </AccordionDetails>
            </Accordion>
          ))
      ) : (
        <div style={{ textAlign: "center" }}>
          <Sentry size={100} color="#19477B" />
        </div>
      )}
    </div>
  );
}

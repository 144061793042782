import React,{useState} from 'react'
import {  useParams,useLocation } from 'react-router-dom'
import ProductList from '../components/Content/ProductList'
import {useProductRequests} from '../helpers/ProductRequests'
import { Helmet } from 'react-helmet'
 
export default function Search(props) {

  const {slug,filter} = useParams()
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [sort, setSort] = useState({
    sort: "PRICE",
    sortedBy: "ASC",
    name: "Artan Fiyat",
  });
  const [selectedBrand,setSelectedBrand]=useState(slug)
  const [search,setSearch]=useState(filter)
  const {getProducts}=useProductRequests()
  
  const simpleCount=20
  function karsilastir(a, b) {
    // "TÜKENDİ" olanları en sonda olacak şekilde sırala
    if (a.stockText === "TÜKENDİ" && b.stockText !== "TÜKENDİ") {
      return 1;
    } else if (a.stockText !== "TÜKENDİ" && b.stockText === "TÜKENDİ") {
      return -1;
    } else {
      // Diğer durumlarda değişiklik yapma
      return 0;
    }
  }
  const getNewProducts=async(newpage,setLoading,setPage)=>{
    setLoading(true)
    getProducts({brandSlug:selectedBrand,sort:sort.sort,sortedBy:sort.sortedBy,count:24,groupSlug:slug,page:newpage,all:search})
    .then(async response=>{
      if(response.data.data.items && response.data.data.items.length<1){
        setLoading(false)
      }else{
        setProducts(([...products,...response.data.data.items]))
      }
    })
  }
  const getSearchedProducts=(setLoading,setPage)=>{
    setLoading(true)
    getProducts({
      brandSlug: selectedBrand,
      count: 24,
      sort: sort.sort,
      sortedBy: sort.sortedBy,
      groupSlug: slug,
      page: 1,
      all: search,
    }).then((response) => {
      if (response.data.data.items && response.data.data.items.length < 1) {
        setLoading(false);
      } else {
        setPage(2);
        setProducts(response.data.data.items);
      }
    });
  }
  React.useEffect(() => {
    setSearch(filter);
  }, [filter]);
  return (
    <>
    <Helmet>
      <title>Ürün Listesi</title>
    </Helmet>
    <ProductList
    karsilastir={karsilastir}
    open={open}
    setOpen={setOpen}
    setSelectedBrand={setSelectedBrand} selectedBrand={selectedBrand}
    slug={slug}
    getNewProducts={getNewProducts}
    getSearchedProducts={getSearchedProducts}
    products={products}
    search={search}
    setSearch={setSearch}
    setProducts={setProducts}
    getProducts={getProducts}
    sort={sort}
    setSort={setSort}
    />
    </>
  )
}

import React,{useState,useLayoutEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import {SlideProductContainer} from '../styles/Home'
import SlideProducts from '../components/Slider/Products'
import Header from '../components/Navigation/Header'
import BottomNav from '../components/Navigation/Bottom'
import Footer from '../components/Navigation/Footer'
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader"

import {useProductRequests} from '../helpers/ProductRequests'

import CartItems from '../components/Cart/CartItems'
import { useCartRequests } from '../helpers/CartRequests'
import { setBasket } from '../components/stores/Cart'

export default function Basket(props) {
  
  const { getDiscover, getMainProducts } = useProductRequests();
    const { addCart, getCart } = useCartRequests();

  
  const {basket}=useSelector(state=>state.cart)
  const dispatch=useDispatch()

  const [discover,setDiscover]=useState([])

  useLayoutEffect(() => {
    
    if(discover.length===0){


      getMainProducts({ type: 1 })
        .then((response) => {
          setDiscover(response.data.data.items);
        })
        .catch((error) => {});

    }
  }, [])
  const [loader,setLoader]=useState(true)
  React.useEffect(() => {
    getCart().then((res) => {
      dispatch(setBasket(res.data.cart));
      setLoader(false);
    });
  
  
  }, [])
  
  const [kamyon,setKamyon]=useState(true)

  React.useEffect(() => {
    const limit = localStorage.getItem("cargoCostLimit") * 1;
    if(localStorage.getItem('customer')==null){
      return
    }
    if (basket== null) {
      getCart().then((res) => {
        dispatch(setBasket(res.data.data));
        setLoader(false);
        setKamyon(
          res.data.data
            .filter((x) => x.id != -1)
            .reduce((a, b) => a + b.salePrice * b.quantity, 0) < limit ? true:false
        );
      });
      return 
    }
    if (
      basket &&
      basket
        .filter((x) => x.id != -1)
        .reduce((a, b) => a + b.salePrice * b.quantity, 0) < limit 
    ) {
      
      getCart().then((res) => {
        dispatch(setBasket(res.data.data));
        setLoader(false);
        setKamyon(true);
      });
    } else if (
      basket &&
      basket.filter((x) => x.id == -1).length === 1 &&
      basket
        .filter((x) => x.id != -1)
        .reduce((a, b) => a + b.salePrice * b.quantity, 0) > limit
    ) {
      
      getCart().then((res) => {
        dispatch(setBasket(res.data.data));
        setLoader(false);
        setKamyon(false);
      });

    } else {
      // getCart().then((res) => {
      //   dispatch(setBasket(res.data.data));
      //   setLoader(false);
      //   setKamyon(false);
      // });

    }

  }, [basket]);
  

  return (
    <>
      <Header />

      <div>
        <CartItems cart={basket} setLoader={setLoader} />

        <SlideProductContainer>
          <h1
            style={{
              color: "rgba(255,10,0)", textAlign: "center",
              paddingTop: 40,
              fontSize: 30,
              marginBottom: 8,
              fontWeight: "bold",
            }}
            // onClick={() => history.push("/yeni-urunler")}
          >
            Kampanyalı Ürünler
          </h1>

          {discover.length > 0 ? (
            <SlideProducts items={discover} />
          ) : (
            <div className="loader">
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "20px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
            </div>
          )}
        </SlideProductContainer>
      </div>

      <BottomNav />

      <Footer />
    </>
  );
}
